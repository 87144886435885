import React, { useState } from "react";
import { stateToHTML } from "draft-js-export-html";
import style from "../style/ClientBenefits.module.css";
import { useDispatch, useSelector } from "react-redux";
import { addGlobalText } from "../redux/actions/auth";
import { message } from "antd";
import { Tabs } from "antd";
import EmailEditor from "../components/EmailEditor";
import ReportSettings from "../components/ReportSettings";
import { Link } from "react-router-dom";
//about editor
import AboutEditor from "./AboutEditor";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import {
  EditorState,
  ContentState,
  convertToRaw,
  convertFromHTML,
} from "draft-js";
import htmlToDraft from "html-to-draftjs";
import draftToHtml from "draftjs-to-html";

const { TabPane } = Tabs;

export default function Communications() {
  const dispatch = useDispatch();
  const user = useSelector((state) => state.auth.user);

  const initialState = () => EditorState.createEmpty();

  const [editorState, setEditorState] = useState(
    user.about
      ? EditorState.createWithContent(
          ContentState.createFromBlockArray(convertFromHTML(user.about))
        )
      : initialState
  );

  const AddData = async (addItem) => {
    const data = draftToHtml(convertToRaw(editorState.getCurrentContent()));
    const aa = data.concat(`<span>${addItem}</span>`);

    const contentBlock = htmlToDraft(aa);
    const contentState = ContentState.createFromBlockArray(
      contentBlock?.contentBlocks
    );

    const updateState = await EditorState.createWithContent(contentState);
    setEditorState(updateState);
  };

  const onFinish = async (values) => {
    let html = stateToHTML(editorState.getCurrentContent());
    const result = await dispatch(addGlobalText({ about: html }));
    if (result) message.success("About text added successfully");
  };

  const myTemplate = `Hello team, and welcome to your Climate Benefits dashboard!

At our company, we believe that we each play a critical role in combating climate change. We know it is important to constantly be educating ourselves and engaging with actions to reduce carbon emissions. Read below about the Climate Benefits program we've launched as a benefit that all of you can participate in.

Here's an overview:
<ul>
 	<li>We've partnered with <a class="PrimaryLink BaseLink" href="https://www.climatebenefits.com/" target="_blank" rel="noreferrer noopener">Climate Benefits</a> to provide this service to our Employees. We're dedicated to combating climate change and want this to be our first step in this direction.</li>
 	<li>We've given every employee $[Monthly Stipend] per month to allocate to carbon removal projects of your choice. We've also allocated $[Employer Matching Stipend] per employee / month in 'employer matching'. Meaning, if you pay out of pocket for carbon removal projects, we'll match those contributions up to $[Employer Matching Stipend]!</li>
 	<li>You can track our company's environmental impact from the <a class="PrimaryLink BaseLink" href="https://www.app.climatebenefits.com/employee" target="_blank" rel="noreferrer noopener">Home page</a>. If you have any questions, please reach out to [Your Name]. You can also contact the team at Climate Benefits directly by clicking "Support". There's also a link there to leave "Feedback" in case there are more features that you want to see.</li>
</ul>`;
  return (
    <div style={{ paddingBottom: "80px" }}>
      <Tabs defaultActiveKey='1'>
        <TabPane tab='About Page' key='1'>
          <h1 className={`mb-2 ${style.mainHeading}`}>
            Help your employees understand Climate Benefits at your organization
          </h1>
          <h5 className='mb-4'>
            This content will appear on the "About" page for Employees
          </h5>
          <div className='row'>
            <div className='col-10'>
              <AboutEditor
                editorState={editorState}
                setEditorState={setEditorState}
              />
            </div>
            <div className='col-md-12'>
              <button
                type='submit'
                className='primaryBtn'
                onClick={() => onFinish()}>
                Save
              </button>
              <Link
                className='ml-3'
                style={{ fontWeight: 500 }}
                onClick={() => AddData(myTemplate)}>
                Use Our Template
              </Link>
            </div>
          </div>
        </TabPane>
        <TabPane tab='Email Invitation' key='2'>
          <h1 className={`mb-4 ${style.mainHeading}`}>
            Customize the automated email that your employees receive when you
            invite them to Climate Benefits
          </h1>
          <EmailEditor />
        </TabPane>
        <TabPane tab='External Report' key='3'>
          <ReportSettings />
        </TabPane>
      </Tabs>
    </div>
  );
}
