import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Modal, Button, Space } from "antd";
import { QuestionCircleOutlined } from "@ant-design/icons";
import { useHistory } from "react-router-dom";
import { setActiveTicket } from "../redux/actions/dashboard";
import style from "../style/teams.module.css";
import moment from "moment";
import Feedback from "../components/headerModal/Feedback";
import SubmitTicketModal from "../components/SubmitTicketModal";
import SupportViewModal from "../components/SupportViewModal";
import { deleteTicket } from "../redux/actions/dashboard";

const { confirm } = Modal;

export default function Support() {
  const user = useSelector((state) => state.auth.user);
  const dispatch = useDispatch();
  const [modalVisible, setModalVisible] = useState(false);
  const [supportModal, setSupportModal] = useState(false);
  const [appChatModal, setAppChatModal] = useState(false);
  const [feedbackModal, setFeedbackModal] = useState(false);
  const supportTickets = useSelector((state) => state.dashboard.tickets);
  const history = useHistory();

  const [cards, setCards] = useState([
    { icon: "ticket.svg", title: "Submit a Ticket" },
    { icon: "email.svg", title: "Email Us" },
    { icon: "star.svg", title: "Leave Feedback" },
    { icon: "document.svg", title: "Documentation" },
  ]);

  function showDeleteConfirm(ticket) {
    confirm({
      title: "Are you sure delete this Ticket?",
      icon: (
        <QuestionCircleOutlined style={{ height: "20px", width: "20px" }} />
      ),
      content: `Ticket Number : ${ticket?.ticketId}`,
      okText: "Yes",
      okType: "danger",
      cancelText: "No",
      onOk() {
        dispatch(deleteTicket(ticket?._id));
        Modal.success({
          title: `Ticket Number: ${ticket?.ticketId}`,
          content: "Ticket has been Deleted Successfully.",
        });
      },
      onCancel() {
        // console.log("Cancel");
      },
    });
  }

  return (
    <div>
      <Feedback
        feedbackModal={feedbackModal}
        setFeedbackModal={(item) => setFeedbackModal(item)}
      />
      <SubmitTicketModal
        modalVisible={modalVisible}
        setModalVisible={() => setModalVisible(false)}
      />
      <SupportViewModal visible={supportModal} setVisible={setSupportModal} />
      {/* {deleteConfirm && showDeleteConfirm()} */}
      <h1
        className='mb-0'
        style={{
          fontFamily: "'Inter', sans-serif",
          fontStyle: "normal",
          fontWeight: "600",
          fontSize: "26px",
          lineHeight: "31px",
          color: "#0A083B",
          margin: "0",
        }}>
        Support
      </h1>
      <div className='row mt-3'>
        {cards.map((item, i) => (
          <div key={i} className='col-md-6 mb-4'>
            <div
              className='smallCard'
              onClick={() =>
                i == 0
                  ? setModalVisible(true)
                  : i == 1
                  ? window.open("mailto: support@climatebenefits.com")
                  : i == 2
                  ? setFeedbackModal(true)
                  : i == 3
                  ? window.open("https://docs.climatebenefits.com/", "_blank")
                  : ""
              }>
              <img
                src={`/images/${item.icon}`}
                style={{ width: "35px" }}
                alt=''
                className='mb-3'
              />
              <h5>{item.title}</h5>
            </div>
          </div>
        ))}
      </div>
      <div style={{ overflow: "auto", paddingBottom: "100px" }}>
        <h1
          className='mb-0 mt-5 mb-3'
          style={{
            fontFamily: "'Inter', sans-serif",
            fontStyle: "normal",
            fontWeight: "600",
            fontSize: "26px",
            lineHeight: "31px",
            color: "#0A083B",
            margin: "0",
          }}>
          Support Tickets
        </h1>
        <table className={style.teamTable}>
          <colgroup>
            <col width='20%' />
            <col width='20%' />
            <col width='20%' />
            <col width='20%' />
            <col width='20%' />
          </colgroup>
          <thead>
            <tr>
              <th>Ticket Number</th>
              <th>Subject</th>
              <th>Status</th>
              <th>Date Created</th>
              <th>Actions</th>
            </tr>
          </thead>
          <tbody>
            {supportTickets.map((item, index) => (
              <tr key={index}>
                <td>{item.ticketId}</td>
                <td>{item.subject}</td>
                <td>{item.status}</td>
                <td>{moment(item.createdAt).format("DD/MM/YYYY")}</td>
                <td>
                  {/* <a
                    className='primaryBtn bg-danger px-4 py-2'
                    style={{
                      display: "inline-block",
                      fontSize: "1.025em",
                      color: "white",
                    }}
                    onClick={(e) => {
                      e.preventDefault();
                      setSupportModal(true);

                      dispatch(setActiveTicket(item));
                    }}>
                    Delete
                  </a> */}

                  <button
                    className='primaryBtn bg-danger px-4 py-2'
                    style={{
                      display: "inline-block",
                      fontSize: "1.025em",
                      color: "white",
                    }}
                    type='button'
                    onClick={() => {
                      showDeleteConfirm(item);
                    }}>
                    Delete
                  </button>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </div>
  );
}
