import React, { useState } from "react";
import style from "../style/measure.module.css";
import stepsstyle from "../style/steps.module.css";
import { DribbbleOutlined } from "@ant-design/icons";
import { Tooltip, Select, Input, Radio, Spin, Form } from "antd";
import countryList from "../data/countries.json";
import industryList from "../data/industries.json";
import { changeCarbonFootprint } from "../redux/actions/benefits";
import clientstyle from "../style/ClientBenefits.module.css";
import PurchaseAnalysisModal from "../components/PurchaseAnalysisModal";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";

const { Option } = Select;

export default function Measure() {
  const [loading, setLoading] = useState(false);
  const [showResult, setShowResult] = useState(false);
  const [result, setResult] = useState(false);
  const [modalVisible, setModalVisible] = useState(false);
  const { carbonMeasures } = useSelector((state) => state.dashboard);
  const [country, setCountry] = useState(
    carbonMeasures.country ? carbonMeasures.country : ""
  );
  const dispatch = useDispatch();
  const calTotalBiz = (
    country,
    industry,
    employees,
    employeesRemote,
    offices,
    energyEfficiency
  ) => {
    var total = 0;
    const countryValue = countryList.find(
      (c) => c.name.toLowerCase() == country.trim().toLowerCase()
    ).value;

    total = countryValue / 14.4;
    var employeeRemoteDecimal = employeesRemote / 100;
    total += 13.5 * employees * (1 - employeeRemoteDecimal);
    total += 13.5 * employees * employeeRemoteDecimal * 0.1;
    total += energyEfficiency ? 45 * offices : 60 * offices;
    total *= industryList.find(
      (i) => i.name.toLowerCase() == industry.trim().toLowerCase()
    ).value;

    return Number(total.toFixed(2));
  };

  const calcTotalEnergy = (
    country,
    electricity,
    energyEfficiency,
    energyProgram
  ) => {
    var total = 0;
    const countryValue = countryList.find(
      (c) => c.name.toLowerCase() == country.trim().toLowerCase()
    ).value;
    total = countryValue / 14.4;
    var s = (12 * electricity * 0.331) / 1e3;
    total = s + s / 0.32;
    if (energyEfficiency) total *= 0.75;
    if (energyProgram) total *= 0.7778;
    return Number(total.toFixed(2));
  };

  const calcTotalTravel = (flights) => {
    var total = flights * 0.6875;
    return Number(total.toFixed(2));
  };

  const calculateCarbonFootprint = ({
    country,
    industry,
    employees,
    employeesRemote,
    offices,
    energyEfficiency,
    electricity,
    energyProgram,
    flights,
  }) => {
    const totalBiz = calTotalBiz(
      country,
      industry,
      employees,
      employeesRemote,
      offices,
      energyEfficiency
    );

    const totalTravel = calcTotalTravel(flights);
    const totalEnergy = calcTotalEnergy(
      country,
      electricity,
      energyEfficiency,
      energyProgram
    );
    const total = totalBiz + totalTravel + totalEnergy;
    return total.toFixed(2);
  };

  const onFinish = async (values) => {
    setLoading(true);

    // return;
    const result = calculateCarbonFootprint({ ...values, country });
    dispatch(
      changeCarbonFootprint({ carbonFootprint: result, ...values, country })
    );
    setLoading(false);
    setResult(result);
    setShowResult(true);
  };

  return (
    <>
      <div className={stepsstyle.Steps}>
        <PurchaseAnalysisModal
          visible={modalVisible}
          setFeedbackModal={setModalVisible}
        />
        <h1 className="mb-3">Calculate Your Carbon Footprint</h1> 
        <h5 className="mb-5">
          These questions help measure the major areas of your business's
          carbon, GHG, and ecological footprint. Remember, this calculator gives
          a rough estimate within a confidence interval.
        </h5>
        <Form
          name="basic"
          initialValues={{
            industry: "Average",
            employees: 1,
            employeesRemote: 1,
            offices: 1,
            energyEfficiency: false,
            electricity: 0,
            energyProgram: false,
            flights: 0,
            ...carbonMeasures,
          }}
          onFinish={onFinish}
        >
          <div className="d-flex flex-wrap mb-5 container px-5 justify-content-center">
            <div className={`${style.measureCard}   flex-column`}>
              <div className="d-flex justify-content-center align-items-center">
                <span className={style.topHeading}>Location</span>
              </div>
              <div>
                <h3 className={style.secondHeading}>
                  Where is your business located?
                </h3>
                <p>
                  If your organization's international, choose the country where
                  the largest % of your operations are based
                </p>
                <Form.Item name={"country"}>
                  <Select
                    className={clientstyle.input}
                    style={{ width: "223px" }}
                    showSearch={false}
                    placeholder="Select a country"
                    onChange={(value) => {
                      setCountry(value);
                    }}
                    value={country}
                    defaultValue={carbonMeasures.country}
                  >
                    {countryList.map((item) => (
                      <Option value={item.name}>{item.name}</Option>
                    ))}
                  </Select>
                </Form.Item>
              </div>
            </div>
            <div className={`${style.measureCard}   flex-column`}>
              <div className="d-flex justify-content-center align-items-center">
                <span className={style.topHeading}>Industry</span>
              </div>
              <div>
                <h3 className={style.secondHeading}>
                  What industry best describes your business?
                </h3>
                <p></p>
                <Form.Item name={"industry"}>
                  <Select
                    className={clientstyle.input}
                    style={{ width: "223px" }}
                    showSearch={false}
                  >
                    {industryList.map((item) => (
                      <Option value={item.name}>{item.name}</Option>
                    ))}
                  </Select>
                </Form.Item>
              </div>
            </div>
            <div className={`${style.measureCard}   flex-column`}>
              <div className="d-flex justify-content-center align-items-center">
                <span className={style.topHeading}>Offices</span>
              </div>
              <div>
                <h3 className={style.secondHeading}>
                  How many offices or sites does your business have?
                </h3>
                <p>
                  Include offices, manufacturing facilities, and other sites
                </p>
                <Form.Item name={"offices"}>
                  <Input className={clientstyle.input} suffix={"Sites"} />
                </Form.Item>
              </div>
            </div>
            <div className={`${style.measureCard}   flex-column`}>
              <div className="d-flex justify-content-center align-items-center">
                <span className={style.topHeading}> Employees and Work</span>
              </div>
              <div>
                <h3 className={style.secondHeading}>
                  How many employees does your business have?
                </h3>
                <p style={{ minHeight: "93px" }}></p>
                <Form.Item name={"employees"}>
                  <Input className={clientstyle.input} suffix={"Employees"} />
                </Form.Item>
              </div>
            </div>
            <div className={`${style.measureCard}   flex-column`}>
              <div className="d-flex justify-content-center align-items-center">
                <span className={style.topHeading}> Employees and Work</span>
              </div>
              <div>
                <h3 className={style.secondHeading}>
                  What percentage of your employees work remotely?
                </h3>
                <p style={{ minHeight: "92px" }}>
                  Count workers who are mostly or full-time remote and don't
                  commute
                </p>
                <Form.Item name={"employeesRemote"}>
                  <Input className={clientstyle.input} suffix={"%"} />
                </Form.Item>
              </div>
            </div>
            <div className={`${style.measureCard}   flex-column`}>
              <div className="d-flex justify-content-center align-items-center">
                <span className={style.topHeading}>Travel</span>
              </div>
              <div>
                <h3 className={style.secondHeading}>
                  How many flights do your employees take per year on average?
                </h3>
                <p>
                  Count each way as 1 flight. For example, if you fly NYC to LA
                  round-trip, count as "2" flights
                </p>
                <Form.Item name={"flights"}>
                  <Input className={clientstyle.input} suffix={"Flights"} />
                </Form.Item>
              </div>
            </div>
            <div className={`${style.measureCard}   flex-column`}>
              <div className="d-flex justify-content-center align-items-center">
                <span className={style.topHeading}> Energy & Utilities</span>
              </div>
              <div>
                <h3 className={style.secondHeading}>
                  How much electricity does your business use every month?
                </h3>
                <p>
                  Average in kWh from your monthly bill or meter readings across
                  offices and sites If you don't know or don't have this
                  information, it's ok, we'll still give you a rough estimate
                </p>
                <Form.Item name={"electricity"}>
                  <Input className={clientstyle.input} suffix={"kWh"} />
                </Form.Item>
              </div>
            </div>
            <div className={`${style.measureCard}   flex-column`}>
              <div className="d-flex justify-content-center align-items-center">
                <span className={style.topHeading}> Energy & Utilities</span>
              </div>
              <div>
                <h3 className={style.secondHeading}>
                  Does your business buy or get power from clean energy?
                </h3>
                <p>
                  This is a clean energy purchase program offered by your power
                  utility, a dedicated plan like Clean Choice Energy, or
                  installing on-site solar panels or wind turbine
                </p>
                <div style={{ textAlign: "center", paddingTop: "15px" }}>
                  <Form.Item name={"energyProgram"}>
                    <Radio.Group>
                      <Radio value={true}>Yes</Radio>
                      <Radio value={false}>No</Radio>
                    </Radio.Group>
                  </Form.Item>
                </div>
              </div>
            </div>
            <div className={`${style.measureCard}   flex-column`}>
              <div className="d-flex justify-content-center align-items-center">
                <span className={style.topHeading}> Energy & Utilities</span>
              </div>
              <div>
                <h3 className={style.secondHeading}>
                  Are your offices or facilities energy efficient, Energy Star,
                  and/or LEED certified?
                </h3>
                <p style={{ minHeight: "92px" }} className="mt-4">
                  Energy efficient, green buildings use less energy and have a
                  lower environmental and carbon footprint
                </p>
                <div style={{ textAlign: "center", paddingTop: "15px" }}>
                  <Form.Item name={"energyEfficiency"}>
                    <Radio.Group>
                      <Radio value={true}>Yes</Radio>
                      <Radio value={false}>No</Radio>
                    </Radio.Group>
                  </Form.Item>
                </div>
              </div>
            </div>
          </div>
          {showResult ? (
            <div className=" mb-5">
              <div
                className={`${style.measureCard}   flex-column mx-auto`}
                style={{ backgroundColor: "#07e260", maxWidth: "460px" }}
              >
                <div className="d-flex justify-content-center align-items-center">
                  <span
                    style={{ color: "white", textAlign: "center" }}
                    className={style.topHeading}
                  >
                    ESTIMATED BUSINESS CARBON FOOTPRINT
                  </span>
                </div>
                <div>
                  <h3
                    style={{
                      fontWeight: 900,
                      fontSize: "50pt",
                      color: "white",
                    }}
                    className={`${style.secondHeading} my-4`}
                  >
                    {result}
                  </h3>

                  <p
                    style={{
                      color: "white",
                      opacity: ".7",
                      borderBottom: "none",
                      minHeight: "auto",
                    }}
                  >
                    TONS OF CO2 PER YEAR
                  </p>
                </div>
              </div>
              <p className="w-100 text-center  mt-4">
                We've updated your Carbon Footprint data on the{" "}
                <Link to="/employer">Home page.</Link>
              </p>
            </div>
          ) : (
            ""
          )}
          <div className="d-flex justify-content-center">
            {loading ? (
              <div className="ml-3 my-3 mr-0">
                <Spin color="white" />
              </div>
            ) : (
              <button className="primaryBtn px-5 mb-5" type="submit">
                Save
              </button>
            )}
          </div>
        </Form>
      </div>
    </>
  );
}
